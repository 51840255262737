var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "genInfoForm",
      attrs: { model: _vm.info, rules: _vm.rules, "label-width": "150px" },
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "tplCategory" } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v("生成模板"),
                  ]),
                  _c(
                    "el-select",
                    {
                      on: { change: _vm.tplSelectChange },
                      model: {
                        value: _vm.info.tplCategory,
                        callback: function ($$v) {
                          _vm.$set(_vm.info, "tplCategory", $$v)
                        },
                        expression: "info.tplCategory",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "单表（增删改查）", value: "crud" },
                      }),
                      _c("el-option", {
                        attrs: { label: "树表（增删改查）", value: "tree" },
                      }),
                      _c("el-option", {
                        attrs: { label: "主子表（增删改查）", value: "sub" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "packageName" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(" 生成包路径 "),
                      _c(
                        "el-tooltip",
                        { attrs: { content: "", placement: "top" } },
                        [_c("i", { staticClass: "el-icon-question" })]
                      ),
                    ],
                    1
                  ),
                  _c("el-input", {
                    model: {
                      value: _vm.info.packageName,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "packageName", $$v)
                      },
                      expression: "info.packageName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "moduleName" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(" 生成模块名 "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "可理解为子系统名，例如 system",
                            placement: "top",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      ),
                    ],
                    1
                  ),
                  _c("el-input", {
                    model: {
                      value: _vm.info.moduleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "moduleName", $$v)
                      },
                      expression: "info.moduleName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "businessName" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(" 生成业务名 "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "可理解为功能英文名，例如 user",
                            placement: "top",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      ),
                    ],
                    1
                  ),
                  _c("el-input", {
                    model: {
                      value: _vm.info.businessName,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "businessName", $$v)
                      },
                      expression: "info.businessName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "functionName" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(" 生成功能名 "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "用作类描述，例如 用户",
                            placement: "top",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      ),
                    ],
                    1
                  ),
                  _c("el-input", {
                    model: {
                      value: _vm.info.functionName,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "functionName", $$v)
                      },
                      expression: "info.functionName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(" 上级菜单 "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "分配到指定菜单下，例如 系统管理",
                            placement: "top",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      ),
                    ],
                    1
                  ),
                  _c("treeselect", {
                    attrs: {
                      "append-to-body": true,
                      options: _vm.menus,
                      normalizer: _vm.normalizer,
                      "show-count": true,
                      placeholder: "请选择系统菜单",
                    },
                    model: {
                      value: _vm.info.parentMenuId,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "parentMenuId", $$v)
                      },
                      expression: "info.parentMenuId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "genType" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(" 生成代码方式 "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content:
                              "默认为zip压缩包下载，也可以自定义生成路径",
                            placement: "top",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      model: {
                        value: _vm.info.genType,
                        callback: function ($$v) {
                          _vm.$set(_vm.info, "genType", $$v)
                        },
                        expression: "info.genType",
                      },
                    },
                    [_vm._v("zip压缩包")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.info.genType,
                        callback: function ($$v) {
                          _vm.$set(_vm.info, "genType", $$v)
                        },
                        expression: "info.genType",
                      },
                    },
                    [_vm._v("自定义路径")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.info.genType == "1"
            ? _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "genPath" } },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" 自定义路径 "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content:
                                  "填写磁盘绝对路径，若不填写，则生成到当前Web项目下",
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-question" })]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-input",
                        {
                          model: {
                            value: _vm.info.genPath,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "genPath", $$v)
                            },
                            expression: "info.genPath",
                          },
                        },
                        [
                          _c(
                            "el-dropdown",
                            { attrs: { slot: "append" }, slot: "append" },
                            [
                              _c("el-button", { attrs: { type: "primary" } }, [
                                _vm._v(" 最近路径快速选择 "),
                                _c("i", {
                                  staticClass:
                                    "el-icon-arrow-down el-icon--right",
                                }),
                              ]),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          _vm.info.genPath = "/"
                                        },
                                      },
                                    },
                                    [_vm._v("恢复默认的生成基础路径")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.info.tplCategory == "tree",
              expression: "info.tplCategory == 'tree'",
            },
          ],
        },
        [
          _c("h4", { staticClass: "form-header" }, [_vm._v("其他信息")]),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(" 树编码字段 "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "树显示的编码字段名， 如：dept_id",
                            placement: "top",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.info.treeCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.info, "treeCode", $$v)
                        },
                        expression: "info.treeCode",
                      },
                    },
                    _vm._l(_vm.info.columns, function (column, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label:
                            column.columnName + "：" + column.columnComment,
                          value: column.columnName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(" 树父编码字段 "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "树显示的父编码字段名， 如：parent_Id",
                            placement: "top",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.info.treeParentCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.info, "treeParentCode", $$v)
                        },
                        expression: "info.treeParentCode",
                      },
                    },
                    _vm._l(_vm.info.columns, function (column, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label:
                            column.columnName + "：" + column.columnComment,
                          value: column.columnName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(" 树名称字段 "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "树节点的显示名称字段名， 如：dept_name",
                            placement: "top",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.info.treeName,
                        callback: function ($$v) {
                          _vm.$set(_vm.info, "treeName", $$v)
                        },
                        expression: "info.treeName",
                      },
                    },
                    _vm._l(_vm.info.columns, function (column, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label:
                            column.columnName + "：" + column.columnComment,
                          value: column.columnName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.info.tplCategory == "sub",
              expression: "info.tplCategory == 'sub'",
            },
          ],
        },
        [
          _c("h4", { staticClass: "form-header" }, [_vm._v("关联信息")]),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(" 关联子表的表名 "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "关联子表的表名， 如：sys_user",
                            placement: "top",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.subSelectChange },
                      model: {
                        value: _vm.info.subTableName,
                        callback: function ($$v) {
                          _vm.$set(_vm.info, "subTableName", $$v)
                        },
                        expression: "info.subTableName",
                      },
                    },
                    _vm._l(_vm.tables, function (table, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: table.tableName + "：" + table.tableComment,
                          value: table.tableName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(" 子表关联的外键名 "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "子表关联的外键名， 如：user_id",
                            placement: "top",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.info.subTableFkName,
                        callback: function ($$v) {
                          _vm.$set(_vm.info, "subTableFkName", $$v)
                        },
                        expression: "info.subTableFkName",
                      },
                    },
                    _vm._l(_vm.subColumns, function (column, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label:
                            column.columnName + "：" + column.columnComment,
                          value: column.columnName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }