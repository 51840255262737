var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        _vm._g(
          _vm._b(
            {
              attrs: {
                width: "500px",
                "close-on-click-modal": false,
                "modal-append-to-body": false,
              },
              on: { open: _vm.onOpen, close: _vm.onClose },
            },
            "el-dialog",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _c(
            "el-row",
            { attrs: { gutter: 15 } },
            [
              _c(
                "el-form",
                {
                  ref: "elForm",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    size: "medium",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "生成类型", prop: "type" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.formData.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "type", $$v)
                                },
                                expression: "formData.type",
                              },
                            },
                            _vm._l(_vm.typeOptions, function (item, index) {
                              return _c(
                                "el-radio-button",
                                {
                                  key: index,
                                  attrs: {
                                    label: item.value,
                                    disabled: item.disabled,
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.showFileName
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "文件名", prop: "fileName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入文件名",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.formData.fileName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "fileName", $$v)
                                  },
                                  expression: "formData.fileName",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v(" 取消 ")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }