<template>
  <!-- 整体内容 -->
  <div class="wrap x-f" v-loading="loading" id="wrap">
    <!-- 左边树装选择 -->
    <categoryTreeCard categoryTreeTitle="仓库设置" @treeNodeId="getTreeNodeId" :boxHeight="boxHeight"
      :boxInternalHeight="boxInternalHeight" :categoryTreeOptions="depOptions" />
    <!-- 表格和搜索右边盒子 -->
    <div class="rightBox y-b">
      <!-- 表格搜索条件 -->
      <cardTitleCom cardTitle="查询" class="headGoodsSearch" id="search-card">
        <template slot="cardContent">
          <div class="navSearch">
            <!-- 高级搜索按钮 -->
            <seniorSearch v-model="queryParams" :getList="getList" :dropDownList="dropDownList"
              :isAdvancedSearch="false" :isBillDate="false" :isSearchInput="true" isSearchInputTitle="输入仓库编码、名称" />
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="单据信息" class="headGoodsTable">
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <!-- 操作按钮 -->
            <operatingButton :getList="getList" :isRecycleBtn="true" :isDeleteBtn="false" :multiple="multiple"
              @handleAdd="handleAdd" @handleDelete="handleDelete" @handleRedoDelete="dialogOptions.show = true" />
            <div>
              <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                @selection-change="handleSelectionChange" border v-loading="loadingTable" @row-click="handleRowClick"
                :max-height="tableHeight" :height="tableHeight">
                <el-table-column type="selection" width="50" align="center" />
                <el-table-column label="序号" width="80" type="index" align="center" key="sortNo1" prop="sortNo1" />
                <el-table-column prop="storeNo" label="仓库编码" :show-overflow-tooltip="true" align="center" width="180"
                  key="storeNo">
                  <template slot-scope="scope">
                    <el-link type="primary" @click="handleDetail(scope.row)" target="_blank">
                      {{ scope.row.storeNo }}
                    </el-link>
                  </template>
                </el-table-column>

                <el-table-column prop="storeName" label="仓库名称" :show-overflow-tooltip="true" align="center"
                  key="storeName" width="180">
                </el-table-column>

                <el-table-column label="所属机构" align="center" key="deptName" prop="deptName"
                  :show-overflow-tooltip="true" width="120" />

                <el-table-column label="仓库类型" align="center" prop="storeType">
                  <template slot-scope="scope">
                    <dict-tag :options="dict.type.store_type" :value="scope.row.storeType" />
                  </template>
                </el-table-column>

                <el-table-column label="仓库状态" align="center" prop="status">
                  <template slot-scope="scope">
                    <dict-tag :options="dict.type.store_status" :value="scope.row.status" />
                  </template>
                </el-table-column>
                <el-table-column label="联系人" align="center" key="employeeName" prop="employeeName" width="120"
                  :show-overflow-tooltip="true" />
                <el-table-column label="联系电话" align="center" key="storeTel" prop="storeTel" width="110"
                  :show-overflow-tooltip="true" />
                <el-table-column label="地址" align="center" prop="storeAddress" :show-overflow-tooltip="true"
                  min-width="120" />

                <el-table-column label="备注" align="center" prop="remark" min-width="120"
                  :show-overflow-tooltip="true" />
                <el-table-column label="修改人" align="center" prop="updateBy" width="120" :show-overflow-tooltip="true" />
                <el-table-column label="修改时间" align="center" prop="updateTime" width="155"
                  :show-overflow-tooltip="true" />
                <el-table-column label="创建人" align="center" prop="createBy" width="120" :show-overflow-tooltip="true" />
                <el-table-column label="创建时间" align="center" prop="createTime" width="155"
                  :show-overflow-tooltip="true" />
              </el-table>
              <bottomPage v-model="queryParams" :getList="getList" :idsCom="ids" :totalCom="total"
                @selectAllCom="selectAll" />
            </div>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <!-- 添加或修改仓库对话框 -->
    <c-dialog :title="title" :width="746" :showDialog.sync="open">
      <template #content>
        <el-scrollbar style="height: 450px">
          <el-form ref="form" :model="storeForm" :rules="rules" label-width="100px" v-loading="DetailLoading">
            <!-- 基本信息 -->
            <div class="inforTitle">基本信息</div>
            <!-- 基本信息第一层 -->
            <el-row>
              <el-col :span="12">
                <el-form-item label="仓库编码" prop="storeNo">
                  <el-input v-model="storeForm.storeNo" placeholder="请输入仓库编码" maxlength="11" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="仓库名称" prop="storeName">
                  <el-input v-model="storeForm.storeName" placeholder="请输入仓库名称" maxlength="11" />
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 基本信息第二层 -->
            <el-row>
              <el-col :span="12">
                <el-form-item label="所属部门" prop="deptId">
                  <treeselect v-model="storeForm.deptId" :options="depOptions" :show-count="true" :disabled="forbidden"
                    placeholder="请选择所属部门" :normalizer="normalizer" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="仓库类型" prop="storeType">
                  <el-select class="select" v-model="storeForm.storeType" placeholder="请选择仓库类型" clearable
                    :disabled="forbidden">
                    <el-option v-for="dict in dict.type.store_type" :key="dict.value" :label="dict.label"
                      :value="parseInt(dict.value)"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 基本信息第三层 -->
            <div class="status">
              <el-form-item label="仓库状态">
                <el-radio-group v-model="storeForm.status">
                  <el-radio :disabled="storeForm.status != 0" :label="0">锁定</el-radio>
                  <el-radio :disabled="storeForm.status == 0" :label="1">正常</el-radio>
                  <el-radio :disabled="storeForm.status == 0" :label="2">停用</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="允许采购" prop="isAllowPurchase">
                <el-radio-group v-model="storeForm.isAllowPurchase">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="允许销售" prop="isAllowSale">
                <el-radio-group v-model="storeForm.isAllowSale">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="允许负库存" prop="isAllowMinus">
                <el-radio-group v-model="storeForm.isAllowMinus">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="计价方式" prop="costMode">
                <el-radio-group v-model="storeForm.costMode">
                  <!-- <el-radio
              v-for="dict in dict.type.cost_mode"
              :key="dict.value"
              :label="parseInt(dict.value)"
              >{{ dict.label }}
            </el-radio> -->
                  <el-radio :label="0">移动加权</el-radio>
                  <el-radio disabled :label="1">先进先出</el-radio>
                  <el-radio disabled :label="2">后进先出</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <!-- 联系信息 -->
            <div class="inforTitle">联系信息</div>
            <!-- 联系信息第一层 -->
            <el-row>
              <el-col :span="12">
                <el-form-item label="仓库负责人" prop="storeEmployeeId">
                  <treeselect style="width: 250px" v-model="storeForm.storeEmployeeId" :options="contactsList"
                    :show-count="true" placeholder="请选择仓库负责人" :normalizer="normalizerContactsList"
                    @input="inputSelect" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系电话" prop="storeTel">
                  <el-input v-model="storeForm.storeTel" maxlength="11" />
                </el-form-item>
              </el-col>
            </el-row>

            <!-- 联系信息第二层 -->
            <el-row>
              <el-col :span="12">
                <el-form-item label="地址" prop="regionId">
                  <el-cascader ref="tree" style="width: 250px" :options="provinceList" :props="defaultProp"
                    v-model="storeForm.regionId" maxlength="80" placeholder="请选择地址" filterable remote reserve-keyword
                    clearable>
                  </el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 联系信息第三层 -->
            <el-row>
              <el-col :span="24">
                <el-form-item label="详细地址" prop="storeAddress">
                  <el-input v-model="storeForm.storeRegionAddress" maxlength="200" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="备注" prop="remark">
                  <el-input v-model="storeForm.remark" maxlength="200" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-scrollbar>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </template>
    </c-dialog>
    <!-- 回收站 -->
    <Dialog2 :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import { getAllRegion } from '@/api/goods/region' //行政树
import { treeselect as getDepTree } from '@/api/system/dept'
import {
  listStore,
  getStoreDetail,
  addStore,
  updateStore,
  delStore,
  restoreStore,
  getGoodsStoreAutoNo
} from '@/api/goods/store'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { allEmployeeList } from '@/api/system/employee'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import categoryTreeCard from '@/views/components/categoryTreeCard' //选择类别
import bottomPage from '@/views/components/bottomPage' //底部分页
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import operatingButton from '@/views/components/operatingButton' //操作按钮
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import Dialog2 from '@/components/Dialog' //回收站
export default {
  name: 'storeSetup',
  dicts: ['cost_mode', 'store_status', 'store_type'],
  components: {
    Treeselect,
    cardTitleCom,
    categoryTreeCard,
    bottomPage,
    seniorSearch,
    operatingButton,
    CDialog,
    Dialog2
  },
  data() {
    return {
      dialogOptions: {
        type: 'ShopInfo',
        show: false,
        title: '回收站',
        width: '75vw',
        data: {
          getListApi: listStore,
          restoreListApi: restoreStore,
          id: 'storeId',
          search: [
            {
              type: 'filters',
              isRestore: true,
              model: '',
              filters: [
                { filter: 'query', label: '全部' },
                { filter: 'storeNos', label: '仓库编码' },
                { filter: 'storeNames', label: '仓库名称' }
              ]
            },
            {
              type: 'button',
              tip: '查询',
              btnType: 'primary',
              click: 'search',
              isRestore: true
            }
          ],
          columns: [
            {
              prop: 'storeNo',
              label: '门店编码',
              minWidth: 180
            },
            {
              prop: 'storeName',
              label: '门店名称',
              minWidth: 180
            },
            {
              prop: 'deptName',
              label: '所属机构',
              minWidth: 120
            },
            {
              prop: 'storeTypeName',
              label: '仓库类型',
              minWidth: 120
            },
            {
              prop: 'statusName',
              label: '仓库状态',
              minWidth: 120
            },
            {
              prop: 'storeAddress',
              label: '地址',
              minWidth: 120
            },
            {
              prop: 'employeeName',
              label: '联系人',
              minWidth: 120
            },
            {
              prop: 'storeTel',
              label: '联系电话',
              minWidth: 120
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 120
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 120
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 155
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 120
            },
            {
              prop: 'createBy',
              label: '创建时间',
              minWidth: 155
            }
          ]
        }
      },
      selectTreeId: undefined, //tree选中的id
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'storeNos', name: '仓库编码' },
        { parameter: 'storeNames', name: '仓库名称' }
      ],
      loadingTable: false, //表格加载
      forbidden: false, //所属部门禁用
      contactsList: [], //仓库负责人姓名
      provinceList: [], //全部行政地区
      defaultProp: {
        value: 'id',
        label: 'label',
        emitPath: false
      }, //地址
      DetailLoading: false, //弹框详情资料
      // 遮罩层
      loading: false,
      name: [], //选中数组的名称
      ids: [], // 选中数组
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 仓库资料表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 部门树选项
      depOptions: [],
      // 是否显示弹出层
      open: false,
      // 部门名称
      deptName: null,
      // 默认密码
      initPassword: null,
      // 日期范围
      dateRange: [],
      // 岗位选项
      postOptions: [],
      // 角色选项
      roleOptions: [],
      // 表单参数
      storeForm: {
        deptId: null,
        storeNo: null,
        storeName: null,
        storeType: null,
        storeUserId: null,
        storeTel: null,
        storeAddress: null,
        status: 1,
        isAllowPurchase: true,
        isAllowSale: true,
        isAllowMinus: true,
        costMode: 0
      },
      //树规则
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        storeNo: null,
        storeName: null,
        status: null,
        deptId: null
      },
      // 表单校验
      rules: {
        storeTel: [
          {
            required: true,
            message: '联系电话不能为空',
            trigger: ['blur', 'change']
          }, //联系电话
          { pattern: /^[0-9]{1,20}$/, message: '请输入数值类型的字符' }
        ],
        regionId: [
          {
            required: true,
            message: '仓库地址不能为空',
            trigger: ['blur', 'change']
          } //仓库地址
        ],
        storeEmployeeId: [
          {
            required: true,
            message: '仓库负责人不能为空',
            trigger: ['blur', 'change']
          } //负责人
        ],
        storeName: [
          {
            required: true,
            message: '仓库名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        storeNo: [
          {
            required: true,
            message: '仓库编码不能为空',
            trigger: ['blur', 'change']
          }
        ],
        deptId: [
          {
            required: true,
            message: '所属部门不能为空',
            trigger: ['blur', 'change']
          }
        ],
        storeType: [
          {
            required: true,
            message: '仓库类型不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  created() {
    this.loading = true
    this.getList()
    this.getDepTree()
    this.loading = false
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 140
    },
    boxHeight() {
      return this.vivwH - 10
    },
    boxInternalHeight() {
      return this.vivwH - 83
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    //还原刷新页面
    async handleEvent() {
      this.loading = true
      await this.getList()
      await this.getDepTree()
      this.loading = false
    },
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //点击类别树
    getTreeNodeId(value) {
      this.queryParams.deptId = value
      this.selectTreeId = value
      this.getList()
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true
        const res = await listStore({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    // 下拉框校验方法
    inputSelect() {
      this.$refs.form.validateField('storeEmployeeId')
    },

    //  修改\点击编号
    async handleDetail(row) {
      this.reset()
      this.title = '编辑门店仓库'
      this.open = true
      //获取品牌详情
      this.DetailLoading = true
      //编辑时所属部门、仓库类型不能修改
      this.forbidden = true
      const res = await getStoreDetail(row.storeId)
      this.storeForm = res.data
      // if (res.data.isDefault) {
      //   //部门禁用状态
      //   this.forbidden = true
      // } else {
      //   //部门禁用状态
      //   this.forbidden = false
      // }
      this.DetailLoading = false
    },
    //负责人下拉树结构数据转换
    normalizerContactsList(node) {
      return {
        id: node.employeeId,
        label: node.employeeName,
        children: node.children
      }
    },
    //下拉树结构数据转换
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children
      }
    },

    /** 查询仓库列表 */
    getList() {
      this.loadingTable = true
      listStore(this.queryParams).then(response => {
        this.tableData = response.rows
        this.total = response.total
        this.loadingTable = false
      })
    },

    /** 查询部门下拉树结构 */
    getDepTree() {
      getDepTree().then(response => {
        this.depOptions = response.data
      })
      //获取全部行政地区事件
      getAllRegion().then(response => {
        this.provinceList = response.data
      })
      allEmployeeList().then(response => {
        this.contactsList = response.data
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.storeForm = {
        deptId: null,
        storeNo: null,
        storeName: null,
        storeType: null,
        storeUserId: null,
        storeTel: null,
        storeAddress: null,
        status: 1,
        isAllowPurchase: true,
        isAllowSale: true,
        isAllowMinus: true,
        costMode: 0
      }
      this.resetForm('form')
    },

    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.storeId)
      this.name = selection.map(item => item.storeName)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },

    /** 新增按钮操作 */
    async handleAdd() {
      this.reset()
      this.title = '新增门店仓库'
      this.open = true
      this.DetailLoading = true
      //部门禁用状态
      this.forbidden = false
      this.storeForm.deptId = this.selectTreeId
      // 记录操
      try {
        const res = await getGoodsStoreAutoNo()
        this.storeForm.storeNo = res.data
        this.DetailLoading = false
      } catch (error) {
        this.DetailLoading = false
      }
    },

    /** 提交按钮 */
    submitForm: function () {
      let arr = [];
      let data = this.$refs.tree.getCheckedNodes()[0].pathLabels.toString();
      // arr = data.split(",");
      arr = data.replace(/[, ]/g, '');
      this.storeForm.storeAddress = `${arr}${this.storeForm.storeRegionAddress}`
      console.log(this.storeForm, 'storeAddress');
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.storeForm.storeId != undefined) {
            updateStore(this.storeForm).then(response => {
              this.$modal.msgSuccess('修改门店仓库成功')
              this.open = false
              this.getList()
            })
          } else {
            addStore(this.storeForm).then(response => {
              this.$modal.msgSuccess('新增门店仓库成功')
              this.open = false
              this.getList()
            })
          }
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete() {
      this.$confirm(`确定删除所选的<span style="color: #1890ff;"> ${this.ids.length} </span>个仓库吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning',
      })
        .then(() => {
          return delStore(this.ids)
        })
        .then(() => {
          this.$modal.msgSuccess('删除成功')
          this.getList()
        })
        .catch(() => { })
    },

    /** 下载模板操作 */
    importTemplate() {
      this.download(
        'system/user/importTemplate',
        {},
        `user_template_${new Date().getTime()}.xlsx`
      )
    }
  }
}
</script>

<style scoped lang="scss">
//整体盒子
.wrap {
  width: 100%;
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 右边
  .rightBox {
    height: calc(100vh - 98px);
    width: calc(100% - 354px); //商品搜索条件
    .headGoodsSearch {
      width: 100%;
      margin-bottom: 6px;
      // 搜索
      .navSearch {
        padding: 10px 10px 10px 20px;
      }
    }
    //商品表格卡片
    .headGoodsTable {
      height: calc(100% - 94px);
      margin-bottom: 0;
      flex: 1;
      .goodsMessage {
        margin: 6px 10px 0 10px;
      }
    }
  }
  .status {
    ::v-deep .el-form-item {
      margin-bottom: 10px;
    }
  }
}
/* 解决自定义滚动条 x 轴显示问题 */
::v-deep .el-scrollbar:hover > .el-scrollbar__bar,
.el-scrollbar:active > .el-scrollbar__bar,
.el-scrollbar:focus > .el-scrollbar__bar {
  opacity: 0;
}
::v-deep ::-webkit-scrollbar-track {
  background: transparent;
}
//新增修改内部标题
.inforTitle {
  font-weight: 700;
  margin: 0 0 15px 10px;
  font-size: 16px;
}

::v-deep .vue-treeselect__control {
  width: 250px;
}

//treeselect
::v-deep .vue-treeselect__menu-container {
  width: 250px;
}

//treeselect
.select {
  width: 250px;
}
</style>
