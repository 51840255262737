var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "right-board" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "center-tabs",
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "组件属性", name: "field" } }),
          _c("el-tab-pane", { attrs: { label: "表单属性", name: "form" } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "field-box" },
        [
          _c(
            "a",
            {
              staticClass: "document-link",
              attrs: {
                target: "_blank",
                href: _vm.documentLink,
                title: "查看组件文档",
              },
            },
            [_c("i", { staticClass: "el-icon-link" })]
          ),
          _c(
            "el-scrollbar",
            { staticClass: "right-scrollbar" },
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "field" && _vm.showField,
                      expression: "currentTab==='field' && showField",
                    },
                  ],
                  attrs: { size: "small", "label-width": "90px" },
                },
                [
                  _vm.activeData.changeTag
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "组件类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: "100%" },
                              attrs: { placeholder: "请选择组件类型" },
                              on: { change: _vm.tagChange },
                              model: {
                                value: _vm.activeData.tagIcon,
                                callback: function ($$v) {
                                  _vm.$set(_vm.activeData, "tagIcon", $$v)
                                },
                                expression: "activeData.tagIcon",
                              },
                            },
                            _vm._l(_vm.tagList, function (group) {
                              return _c(
                                "el-option-group",
                                {
                                  key: group.label,
                                  attrs: { label: group.label },
                                },
                                _vm._l(group.options, function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.label,
                                      attrs: {
                                        label: item.label,
                                        value: item.tagIcon,
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "node-icon",
                                        attrs: { "icon-class": item.tagIcon },
                                      }),
                                      _c("span", [
                                        _vm._v(" " + _vm._s(item.label)),
                                      ]),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.vModel !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "字段名" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入字段名（v-model）" },
                            model: {
                              value: _vm.activeData.vModel,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "vModel", $$v)
                              },
                              expression: "activeData.vModel",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.componentName !== undefined
                    ? _c("el-form-item", { attrs: { label: "组件名" } }, [
                        _vm._v(
                          " " + _vm._s(_vm.activeData.componentName) + " "
                        ),
                      ])
                    : _vm._e(),
                  _vm.activeData.label !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "标题" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入标题" },
                            model: {
                              value: _vm.activeData.label,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "label", $$v)
                              },
                              expression: "activeData.label",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.placeholder !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "占位提示" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入占位提示" },
                            model: {
                              value: _vm.activeData.placeholder,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "placeholder", $$v)
                              },
                              expression: "activeData.placeholder",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["start-placeholder"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "开始占位" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入占位提示" },
                            model: {
                              value: _vm.activeData["start-placeholder"],
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.activeData,
                                  "start-placeholder",
                                  $$v
                                )
                              },
                              expression: "activeData['start-placeholder']",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["end-placeholder"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "结束占位" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入占位提示" },
                            model: {
                              value: _vm.activeData["end-placeholder"],
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "end-placeholder", $$v)
                              },
                              expression: "activeData['end-placeholder']",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.span !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "表单栅格" } },
                        [
                          _c("el-slider", {
                            attrs: { max: 24, min: 1, marks: { 12: "" } },
                            on: { change: _vm.spanChange },
                            model: {
                              value: _vm.activeData.span,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "span", $$v)
                              },
                              expression: "activeData.span",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.layout === "rowFormItem"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "栅格间隔" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0, placeholder: "栅格间隔" },
                            model: {
                              value: _vm.activeData.gutter,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "gutter", $$v)
                              },
                              expression: "activeData.gutter",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.layout === "rowFormItem"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "布局模式" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.activeData.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.activeData, "type", $$v)
                                },
                                expression: "activeData.type",
                              },
                            },
                            [
                              _c("el-radio-button", {
                                attrs: { label: "default" },
                              }),
                              _c("el-radio-button", {
                                attrs: { label: "flex" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.justify !== undefined &&
                  _vm.activeData.type === "flex"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "水平排列" } },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: "100%" },
                              attrs: { placeholder: "请选择水平排列" },
                              model: {
                                value: _vm.activeData.justify,
                                callback: function ($$v) {
                                  _vm.$set(_vm.activeData, "justify", $$v)
                                },
                                expression: "activeData.justify",
                              },
                            },
                            _vm._l(_vm.justifyOptions, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.align !== undefined &&
                  _vm.activeData.type === "flex"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "垂直排列" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.activeData.align,
                                callback: function ($$v) {
                                  _vm.$set(_vm.activeData, "align", $$v)
                                },
                                expression: "activeData.align",
                              },
                            },
                            [
                              _c("el-radio-button", {
                                attrs: { label: "top" },
                              }),
                              _c("el-radio-button", {
                                attrs: { label: "middle" },
                              }),
                              _c("el-radio-button", {
                                attrs: { label: "bottom" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.labelWidth !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "标签宽度" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              placeholder: "请输入标签宽度",
                            },
                            model: {
                              value: _vm.activeData.labelWidth,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.activeData,
                                  "labelWidth",
                                  _vm._n($$v)
                                )
                              },
                              expression: "activeData.labelWidth",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.style &&
                  _vm.activeData.style.width !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "组件宽度" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入组件宽度",
                              clearable: "",
                            },
                            model: {
                              value: _vm.activeData.style.width,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData.style, "width", $$v)
                              },
                              expression: "activeData.style.width",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.vModel !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "默认值" } },
                        [
                          _c("el-input", {
                            attrs: {
                              value: _vm.setDefaultValue(
                                _vm.activeData.defaultValue
                              ),
                              placeholder: "请输入默认值",
                            },
                            on: { input: _vm.onDefaultValueInput },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.tag === "el-checkbox-group"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "至少应选" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              value: _vm.activeData.min,
                              min: 0,
                              placeholder: "至少应选",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$set(
                                  _vm.activeData,
                                  "min",
                                  $event ? $event : undefined
                                )
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.tag === "el-checkbox-group"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "最多可选" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              value: _vm.activeData.max,
                              min: 0,
                              placeholder: "最多可选",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$set(
                                  _vm.activeData,
                                  "max",
                                  $event ? $event : undefined
                                )
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.prepend !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "前缀" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入前缀" },
                            model: {
                              value: _vm.activeData.prepend,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "prepend", $$v)
                              },
                              expression: "activeData.prepend",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.append !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "后缀" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入后缀" },
                            model: {
                              value: _vm.activeData.append,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "append", $$v)
                              },
                              expression: "activeData.append",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["prefix-icon"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "前图标" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入前图标名称" },
                              model: {
                                value: _vm.activeData["prefix-icon"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.activeData, "prefix-icon", $$v)
                                },
                                expression: "activeData['prefix-icon']",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "append",
                                    icon: "el-icon-thumb",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openIconsDialog("prefix-icon")
                                    },
                                  },
                                  slot: "append",
                                },
                                [_vm._v(" 选择 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["suffix-icon"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "后图标" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入后图标名称" },
                              model: {
                                value: _vm.activeData["suffix-icon"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.activeData, "suffix-icon", $$v)
                                },
                                expression: "activeData['suffix-icon']",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "append",
                                    icon: "el-icon-thumb",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openIconsDialog("suffix-icon")
                                    },
                                  },
                                  slot: "append",
                                },
                                [_vm._v(" 选择 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.tag === "el-cascader"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "选项分隔符" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入选项分隔符" },
                            model: {
                              value: _vm.activeData.separator,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "separator", $$v)
                              },
                              expression: "activeData.separator",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.autosize !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "最小行数" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 1, placeholder: "最小行数" },
                            model: {
                              value: _vm.activeData.autosize.minRows,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.activeData.autosize,
                                  "minRows",
                                  $$v
                                )
                              },
                              expression: "activeData.autosize.minRows",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.autosize !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "最大行数" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 1, placeholder: "最大行数" },
                            model: {
                              value: _vm.activeData.autosize.maxRows,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.activeData.autosize,
                                  "maxRows",
                                  $$v
                                )
                              },
                              expression: "activeData.autosize.maxRows",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.min !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "最小值" } },
                        [
                          _c("el-input-number", {
                            attrs: { placeholder: "最小值" },
                            model: {
                              value: _vm.activeData.min,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "min", $$v)
                              },
                              expression: "activeData.min",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.max !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "最大值" } },
                        [
                          _c("el-input-number", {
                            attrs: { placeholder: "最大值" },
                            model: {
                              value: _vm.activeData.max,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "max", $$v)
                              },
                              expression: "activeData.max",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.step !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "步长" } },
                        [
                          _c("el-input-number", {
                            attrs: { placeholder: "步数" },
                            model: {
                              value: _vm.activeData.step,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "step", $$v)
                              },
                              expression: "activeData.step",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.tag === "el-input-number"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "精度" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0, placeholder: "精度" },
                            model: {
                              value: _vm.activeData.precision,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "precision", $$v)
                              },
                              expression: "activeData.precision",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.tag === "el-input-number"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "按钮位置" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.activeData["controls-position"],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.activeData,
                                    "controls-position",
                                    $$v
                                  )
                                },
                                expression: "activeData['controls-position']",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v(" 默认 "),
                              ]),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "right" } },
                                [_vm._v(" 右侧 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.maxlength !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "最多输入" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入字符长度" },
                              model: {
                                value: _vm.activeData.maxlength,
                                callback: function ($$v) {
                                  _vm.$set(_vm.activeData, "maxlength", $$v)
                                },
                                expression: "activeData.maxlength",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v(" 个字符 "),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["active-text"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "开启提示" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入开启提示" },
                            model: {
                              value: _vm.activeData["active-text"],
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "active-text", $$v)
                              },
                              expression: "activeData['active-text']",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["inactive-text"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "关闭提示" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入关闭提示" },
                            model: {
                              value: _vm.activeData["inactive-text"],
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "inactive-text", $$v)
                              },
                              expression: "activeData['inactive-text']",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["active-value"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "开启值" } },
                        [
                          _c("el-input", {
                            attrs: {
                              value: _vm.setDefaultValue(
                                _vm.activeData["active-value"]
                              ),
                              placeholder: "请输入开启值",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.onSwitchValueInput(
                                  $event,
                                  "active-value"
                                )
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["inactive-value"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "关闭值" } },
                        [
                          _c("el-input", {
                            attrs: {
                              value: _vm.setDefaultValue(
                                _vm.activeData["inactive-value"]
                              ),
                              placeholder: "请输入关闭值",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.onSwitchValueInput(
                                  $event,
                                  "inactive-value"
                                )
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.type !== undefined &&
                  "el-date-picker" === _vm.activeData.tag
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "时间类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: "100%" },
                              attrs: { placeholder: "请选择时间类型" },
                              on: { change: _vm.dateTypeChange },
                              model: {
                                value: _vm.activeData.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.activeData, "type", $$v)
                                },
                                expression: "activeData.type",
                              },
                            },
                            _vm._l(_vm.dateOptions, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.name !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "文件字段名" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入上传文件字段名" },
                            model: {
                              value: _vm.activeData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "name", $$v)
                              },
                              expression: "activeData.name",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.accept !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "文件类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: "100%" },
                              attrs: {
                                placeholder: "请选择文件类型",
                                clearable: "",
                              },
                              model: {
                                value: _vm.activeData.accept,
                                callback: function ($$v) {
                                  _vm.$set(_vm.activeData, "accept", $$v)
                                },
                                expression: "activeData.accept",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "图片", value: "image/*" },
                              }),
                              _c("el-option", {
                                attrs: { label: "视频", value: "video/*" },
                              }),
                              _c("el-option", {
                                attrs: { label: "音频", value: "audio/*" },
                              }),
                              _c("el-option", {
                                attrs: { label: "excel", value: ".xls,.xlsx" },
                              }),
                              _c("el-option", {
                                attrs: { label: "word", value: ".doc,.docx" },
                              }),
                              _c("el-option", {
                                attrs: { label: "pdf", value: ".pdf" },
                              }),
                              _c("el-option", {
                                attrs: { label: "txt", value: ".txt" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.fileSize !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "文件大小" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入文件大小" },
                              model: {
                                value: _vm.activeData.fileSize,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.activeData,
                                    "fileSize",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "activeData.fileSize",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  style: { width: "66px" },
                                  attrs: { slot: "append" },
                                  slot: "append",
                                  model: {
                                    value: _vm.activeData.sizeUnit,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.activeData, "sizeUnit", $$v)
                                    },
                                    expression: "activeData.sizeUnit",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "KB", value: "KB" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "MB", value: "MB" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "GB", value: "GB" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.action !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "上传地址" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入上传地址",
                              clearable: "",
                            },
                            model: {
                              value: _vm.activeData.action,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "action", $$v)
                              },
                              expression: "activeData.action",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["list-type"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "列表类型" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.activeData["list-type"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.activeData, "list-type", $$v)
                                },
                                expression: "activeData['list-type']",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "text" } },
                                [_vm._v(" text ")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "picture" } },
                                [_vm._v(" picture ")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "picture-card" } },
                                [_vm._v(" picture-card ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.buttonText !== undefined
                    ? _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                "picture-card" !== _vm.activeData["list-type"],
                              expression:
                                "'picture-card' !== activeData['list-type']",
                            },
                          ],
                          attrs: { label: "按钮文字" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入按钮文字" },
                            model: {
                              value: _vm.activeData.buttonText,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "buttonText", $$v)
                              },
                              expression: "activeData.buttonText",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["range-separator"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "分隔符" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入分隔符" },
                            model: {
                              value: _vm.activeData["range-separator"],
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "range-separator", $$v)
                              },
                              expression: "activeData['range-separator']",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["picker-options"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "时间段" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入时间段" },
                            model: {
                              value:
                                _vm.activeData["picker-options"]
                                  .selectableRange,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.activeData["picker-options"],
                                  "selectableRange",
                                  $$v
                                )
                              },
                              expression:
                                "activeData['picker-options'].selectableRange",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.format !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "时间格式" } },
                        [
                          _c("el-input", {
                            attrs: {
                              value: _vm.activeData.format,
                              placeholder: "请输入时间格式",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.setTimeValue($event)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  ["el-checkbox-group", "el-radio-group", "el-select"].indexOf(
                    _vm.activeData.tag
                  ) > -1
                    ? [
                        _c("el-divider", [_vm._v("选项")]),
                        _c(
                          "draggable",
                          {
                            attrs: {
                              list: _vm.activeData.options,
                              animation: 340,
                              group: "selectItem",
                              handle: ".option-drag",
                            },
                          },
                          _vm._l(
                            _vm.activeData.options,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "select-item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "select-line-icon option-drag",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-s-operation",
                                      }),
                                    ]
                                  ),
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "选项名",
                                      size: "small",
                                    },
                                    model: {
                                      value: item.label,
                                      callback: function ($$v) {
                                        _vm.$set(item, "label", $$v)
                                      },
                                      expression: "item.label",
                                    },
                                  }),
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "选项值",
                                      size: "small",
                                      value: item.value,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.setOptionValue(item, $event)
                                      },
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "close-btn select-line-icon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.activeData.options.splice(
                                            index,
                                            1
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-remove-outline",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                        _c(
                          "div",
                          { staticStyle: { "margin-left": "20px" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "padding-bottom": "0" },
                                attrs: {
                                  icon: "el-icon-circle-plus-outline",
                                  type: "text",
                                },
                                on: { click: _vm.addSelectItem },
                              },
                              [_vm._v(" 添加选项 ")]
                            ),
                          ],
                          1
                        ),
                        _c("el-divider"),
                      ]
                    : _vm._e(),
                  ["el-cascader"].indexOf(_vm.activeData.tag) > -1
                    ? [
                        _c("el-divider", [_vm._v("选项")]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "数据类型" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.activeData.dataType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.activeData, "dataType", $$v)
                                  },
                                  expression: "activeData.dataType",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "dynamic" } },
                                  [_vm._v(" 动态数据 ")]
                                ),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "static" } },
                                  [_vm._v(" 静态数据 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.activeData.dataType === "dynamic"
                          ? [
                              _c(
                                "el-form-item",
                                { attrs: { label: "标签键名" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入标签键名" },
                                    model: {
                                      value: _vm.activeData.labelKey,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.activeData,
                                          "labelKey",
                                          $$v
                                        )
                                      },
                                      expression: "activeData.labelKey",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "值键名" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入值键名" },
                                    model: {
                                      value: _vm.activeData.valueKey,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.activeData,
                                          "valueKey",
                                          $$v
                                        )
                                      },
                                      expression: "activeData.valueKey",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "子级键名" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入子级键名" },
                                    model: {
                                      value: _vm.activeData.childrenKey,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.activeData,
                                          "childrenKey",
                                          $$v
                                        )
                                      },
                                      expression: "activeData.childrenKey",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.activeData.dataType === "static"
                          ? _c("el-tree", {
                              attrs: {
                                draggable: "",
                                data: _vm.activeData.options,
                                "node-key": "id",
                                "expand-on-click-node": false,
                                "render-content": _vm.renderContent,
                              },
                            })
                          : _vm._e(),
                        _vm.activeData.dataType === "static"
                          ? _c(
                              "div",
                              { staticStyle: { "margin-left": "20px" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "padding-bottom": "0" },
                                    attrs: {
                                      icon: "el-icon-circle-plus-outline",
                                      type: "text",
                                    },
                                    on: { click: _vm.addTreeItem },
                                  },
                                  [_vm._v(" 添加父级 ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("el-divider"),
                      ]
                    : _vm._e(),
                  _vm.activeData.optionType !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "选项样式" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.activeData.optionType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.activeData, "optionType", $$v)
                                },
                                expression: "activeData.optionType",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "default" } },
                                [_vm._v(" 默认 ")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "button" } },
                                [_vm._v(" 按钮 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["active-color"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "开启颜色" } },
                        [
                          _c("el-color-picker", {
                            model: {
                              value: _vm.activeData["active-color"],
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "active-color", $$v)
                              },
                              expression: "activeData['active-color']",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["inactive-color"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "关闭颜色" } },
                        [
                          _c("el-color-picker", {
                            model: {
                              value: _vm.activeData["inactive-color"],
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "inactive-color", $$v)
                              },
                              expression: "activeData['inactive-color']",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["allow-half"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "允许半选" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData["allow-half"],
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "allow-half", $$v)
                              },
                              expression: "activeData['allow-half']",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["show-text"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "辅助文字" } },
                        [
                          _c("el-switch", {
                            on: { change: _vm.rateTextChange },
                            model: {
                              value: _vm.activeData["show-text"],
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "show-text", $$v)
                              },
                              expression: "activeData['show-text']",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["show-score"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "显示分数" } },
                        [
                          _c("el-switch", {
                            on: { change: _vm.rateScoreChange },
                            model: {
                              value: _vm.activeData["show-score"],
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "show-score", $$v)
                              },
                              expression: "activeData['show-score']",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["show-stops"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "显示间断点" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData["show-stops"],
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "show-stops", $$v)
                              },
                              expression: "activeData['show-stops']",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.range !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "范围选择" } },
                        [
                          _c("el-switch", {
                            on: { change: _vm.rangeChange },
                            model: {
                              value: _vm.activeData.range,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "range", $$v)
                              },
                              expression: "activeData.range",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.border !== undefined &&
                  _vm.activeData.optionType === "default"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否带边框" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData.border,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "border", $$v)
                              },
                              expression: "activeData.border",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.tag === "el-color-picker"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "颜色格式" } },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: "100%" },
                              attrs: { placeholder: "请选择颜色格式" },
                              on: { change: _vm.colorFormatChange },
                              model: {
                                value: _vm.activeData["color-format"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.activeData, "color-format", $$v)
                                },
                                expression: "activeData['color-format']",
                              },
                            },
                            _vm._l(
                              _vm.colorFormatOptions,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.size !== undefined &&
                  (_vm.activeData.optionType === "button" ||
                    _vm.activeData.border ||
                    _vm.activeData.tag === "el-color-picker")
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "选项尺寸" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.activeData.size,
                                callback: function ($$v) {
                                  _vm.$set(_vm.activeData, "size", $$v)
                                },
                                expression: "activeData.size",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "medium" } },
                                [_vm._v(" 中等 ")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "small" } },
                                [_vm._v(" 较小 ")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "mini" } },
                                [_vm._v(" 迷你 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["show-word-limit"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "输入统计" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData["show-word-limit"],
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "show-word-limit", $$v)
                              },
                              expression: "activeData['show-word-limit']",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.tag === "el-input-number"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "严格步数" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData["step-strictly"],
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "step-strictly", $$v)
                              },
                              expression: "activeData['step-strictly']",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.tag === "el-cascader"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否多选" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData.props.props.multiple,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.activeData.props.props,
                                  "multiple",
                                  $$v
                                )
                              },
                              expression: "activeData.props.props.multiple",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.tag === "el-cascader"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "展示全路径" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData["show-all-levels"],
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "show-all-levels", $$v)
                              },
                              expression: "activeData['show-all-levels']",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.tag === "el-cascader"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "可否筛选" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData.filterable,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "filterable", $$v)
                              },
                              expression: "activeData.filterable",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.clearable !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "能否清空" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData.clearable,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "clearable", $$v)
                              },
                              expression: "activeData.clearable",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.showTip !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "显示提示" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData.showTip,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "showTip", $$v)
                              },
                              expression: "activeData.showTip",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.multiple !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "多选文件" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData.multiple,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "multiple", $$v)
                              },
                              expression: "activeData.multiple",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["auto-upload"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "自动上传" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData["auto-upload"],
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "auto-upload", $$v)
                              },
                              expression: "activeData['auto-upload']",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.readonly !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否只读" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData.readonly,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "readonly", $$v)
                              },
                              expression: "activeData.readonly",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.disabled !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否禁用" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData.disabled,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "disabled", $$v)
                              },
                              expression: "activeData.disabled",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.tag === "el-select"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否可搜索" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData.filterable,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "filterable", $$v)
                              },
                              expression: "activeData.filterable",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.tag === "el-select"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否多选" } },
                        [
                          _c("el-switch", {
                            on: { change: _vm.multipleChange },
                            model: {
                              value: _vm.activeData.multiple,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "multiple", $$v)
                              },
                              expression: "activeData.multiple",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.required !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否必填" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData.required,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "required", $$v)
                              },
                              expression: "activeData.required",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.layoutTree
                    ? [
                        _c("el-divider", [_vm._v("布局结构树")]),
                        _c("el-tree", {
                          attrs: {
                            data: [_vm.activeData],
                            props: _vm.layoutTreeProps,
                            "node-key": "renderKey",
                            "default-expand-all": "",
                            draggable: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var node = ref.node
                                  var data = ref.data
                                  return _c("span", {}, [
                                    _c(
                                      "span",
                                      { staticClass: "node-label" },
                                      [
                                        _c("svg-icon", {
                                          staticClass: "node-icon",
                                          attrs: { "icon-class": data.tagIcon },
                                        }),
                                        _vm._v(" " + _vm._s(node.label) + " "),
                                      ],
                                      1
                                    ),
                                  ])
                                },
                              },
                            ],
                            null,
                            false,
                            921874089
                          ),
                        }),
                      ]
                    : _vm._e(),
                  _vm.activeData.layout === "colFormItem" &&
                  _vm.activeData.tag !== "el-button"
                    ? [
                        _c("el-divider", [_vm._v("正则校验")]),
                        _vm._l(_vm.activeData.regList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "reg-item" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "close-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.activeData.regList.splice(
                                        index,
                                        1
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-close" })]
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "表达式" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入正则" },
                                    model: {
                                      value: item.pattern,
                                      callback: function ($$v) {
                                        _vm.$set(item, "pattern", $$v)
                                      },
                                      expression: "item.pattern",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: { label: "错误提示" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入错误提示" },
                                    model: {
                                      value: item.message,
                                      callback: function ($$v) {
                                        _vm.$set(item, "message", $$v)
                                      },
                                      expression: "item.message",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        _c(
                          "div",
                          { staticStyle: { "margin-left": "20px" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-circle-plus-outline",
                                  type: "text",
                                },
                                on: { click: _vm.addReg },
                              },
                              [_vm._v(" 添加规则 ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "form",
                      expression: "currentTab === 'form'",
                    },
                  ],
                  attrs: { size: "small", "label-width": "90px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "表单名" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入表单名（ref）" },
                        model: {
                          value: _vm.formConf.formRef,
                          callback: function ($$v) {
                            _vm.$set(_vm.formConf, "formRef", $$v)
                          },
                          expression: "formConf.formRef",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "表单模型" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入数据模型" },
                        model: {
                          value: _vm.formConf.formModel,
                          callback: function ($$v) {
                            _vm.$set(_vm.formConf, "formModel", $$v)
                          },
                          expression: "formConf.formModel",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "校验模型" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入校验模型" },
                        model: {
                          value: _vm.formConf.formRules,
                          callback: function ($$v) {
                            _vm.$set(_vm.formConf, "formRules", $$v)
                          },
                          expression: "formConf.formRules",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "表单尺寸" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formConf.size,
                            callback: function ($$v) {
                              _vm.$set(_vm.formConf, "size", $$v)
                            },
                            expression: "formConf.size",
                          },
                        },
                        [
                          _c(
                            "el-radio-button",
                            { attrs: { label: "medium" } },
                            [_vm._v(" 中等 ")]
                          ),
                          _c("el-radio-button", { attrs: { label: "small" } }, [
                            _vm._v(" 较小 "),
                          ]),
                          _c("el-radio-button", { attrs: { label: "mini" } }, [
                            _vm._v(" 迷你 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标签对齐" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formConf.labelPosition,
                            callback: function ($$v) {
                              _vm.$set(_vm.formConf, "labelPosition", $$v)
                            },
                            expression: "formConf.labelPosition",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "left" } }, [
                            _vm._v(" 左对齐 "),
                          ]),
                          _c("el-radio-button", { attrs: { label: "right" } }, [
                            _vm._v(" 右对齐 "),
                          ]),
                          _c("el-radio-button", { attrs: { label: "top" } }, [
                            _vm._v(" 顶部对齐 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标签宽度" } },
                    [
                      _c("el-input-number", {
                        attrs: { placeholder: "标签宽度" },
                        model: {
                          value: _vm.formConf.labelWidth,
                          callback: function ($$v) {
                            _vm.$set(_vm.formConf, "labelWidth", $$v)
                          },
                          expression: "formConf.labelWidth",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "栅格间隔" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, placeholder: "栅格间隔" },
                        model: {
                          value: _vm.formConf.gutter,
                          callback: function ($$v) {
                            _vm.$set(_vm.formConf, "gutter", $$v)
                          },
                          expression: "formConf.gutter",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "禁用表单" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.formConf.disabled,
                          callback: function ($$v) {
                            _vm.$set(_vm.formConf, "disabled", $$v)
                          },
                          expression: "formConf.disabled",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "表单按钮" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.formConf.formBtns,
                          callback: function ($$v) {
                            _vm.$set(_vm.formConf, "formBtns", $$v)
                          },
                          expression: "formConf.formBtns",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "显示未选中组件边框" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.formConf.unFocusedComponentBorder,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formConf,
                              "unFocusedComponentBorder",
                              $$v
                            )
                          },
                          expression: "formConf.unFocusedComponentBorder",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("treeNode-dialog", {
        attrs: { visible: _vm.dialogVisible, title: "添加选项" },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
          commit: _vm.addNode,
        },
      }),
      _c("icons-dialog", {
        attrs: {
          visible: _vm.iconsVisible,
          current: _vm.activeData[_vm.currentIconModel],
        },
        on: {
          "update:visible": function ($event) {
            _vm.iconsVisible = $event
          },
          select: _vm.setIcon,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }