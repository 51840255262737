<template>
  <div class="wrap" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <el-form
            :model="queryParams"
            ref="queryForm"
            size="small"
            :inline="true"
            v-show="showSearch"
            label-width="68px"
          >
            <div class="x-bc">
              <div>
                <el-form-item label="表名称" prop="tableName">
                  <el-input
                    v-model="queryParams.tableName"
                    placeholder="请输入表名称"
                    clearable
                    @keyup.enter.native="handleQuery"
                    size="mini"
                  />
                </el-form-item>
                <el-form-item label="表描述" prop="tableComment">
                  <el-input
                    v-model="queryParams.tableComment"
                    placeholder="请输入表描述"
                    clearable
                    @keyup.enter.native="handleQuery"
                    size="mini"
                  />
                </el-form-item>
                <el-form-item label="创建时间">
                  <el-date-picker
                    v-model="dateRange"
                    style="width: 240px"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    size="mini"
                  ></el-date-picker>
                </el-form-item>
              </div>
              <div>
                <el-form-item>
                  <el-button type="primary" size="mini" @click="handleQuery"
                    >查询</el-button
                  >
                  <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                  >
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
      </template>
    </cardTitleCom>

    <cardTitleCom cardTitle="代码生成" class="brandInfo">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton
            :isAddBtn="false"
            :isDeleteBtn="false"
            :isExportBtn="false"
          >
            <template #specialDiyBtn>
              <el-button
                type="primary"
                icon="el-icon-download"
                size="mini"
                @click="handleGenTable"
                v-hasPermi="['tool:gen:code']"
                >生成</el-button
              >

              <el-button
                type="info"
                icon="el-icon-upload"
                size="mini"
                @click="openImportTable"
                v-hasPermi="['tool:gen:import']"
                >导入</el-button
              >

              <el-button
                type="success"
                icon="el-icon-edit"
                size="mini"
                :disabled="single"
                @click="handleEditTable"
                v-hasPermi="['tool:gen:edit']"
                >修改</el-button
              >

              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :disabled="multiple"
                @click="handleDelete"
                v-hasPermi="['tool:gen:remove']"
                >删除</el-button
              >
            </template>
          </operatingButton>

          <el-table
            v-loading="loading"
            :data="tableList"
            @selection-change="handleSelectionChange"
            @row-click="handleRowClick"
            ref="multipleTable"
            border
            :max-height="tableHeight"
            :height="tableHeight"
          >
            <el-table-column
              type="selection"
              align="center"
              width="55"
            ></el-table-column>
            <el-table-column
              label="序号"
              type="index"
              width="50"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{
                  (queryParams.pageNum - 1) * queryParams.pageSize +
                  scope.$index +
                  1
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="表名称"
              align="center"
              prop="tableName"
              :show-overflow-tooltip="true"
              width="186"
            />
            <el-table-column
              label="表描述"
              align="center"
              prop="tableComment"
              :show-overflow-tooltip="true"
              width="180"
            />
            <el-table-column
              label="实体"
              align="center"
              prop="className"
              :show-overflow-tooltip="true"
              width="168"
            />
            <el-table-column
              label="创建时间"
              align="center"
              prop="createTime"
              width="160"
            />
            <el-table-column
              label="更新时间"
              align="center"
              prop="updateTime"
              width="160"
            />
            <el-table-column
              label="操作"
              align="center"
              class-name="small-padding fixed-width"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  icon="el-icon-view"
                  @click="handlePreview(scope.row)"
                  v-hasPermi="['tool:gen:preview']"
                  >预览</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  icon="el-icon-edit"
                  @click="handleEditTable(scope.row)"
                  v-hasPermi="['tool:gen:edit']"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row)"
                  v-hasPermi="['tool:gen:remove']"
                  >删除</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  icon="el-icon-refresh"
                  @click="handleSynchDb(scope.row)"
                  v-hasPermi="['tool:gen:edit']"
                  >同步</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  icon="el-icon-download"
                  @click="handleGenTable(scope.row)"
                  v-hasPermi="['tool:gen:code']"
                  >生成代码</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :idsCom="ids"
            :totalCom="total"
            @selectAllCom="selectAll"
          />
        </div>
      </template>
    </cardTitleCom>

    <!-- <right-toolbar
        :showSearch.sync="showSearch"
        @queryTable="getList"
      ></right-toolbar> -->

    <!-- <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    /> -->
    <!-- 预览界面 -->
    <el-dialog
      :title="preview.title"
      :visible.sync="preview.open"
      width="80%"
      top="5vh"
      append-to-body
      class="scrollbar"
    >
      <el-tabs v-model="preview.activeName">
        <el-tab-pane
          v-for="(value, key) in preview.data"
          :label="key.substring(key.lastIndexOf('/') + 1, key.indexOf('.vm'))"
          :name="key.substring(key.lastIndexOf('/') + 1, key.indexOf('.vm'))"
          :key="key"
        >
          <el-link
            :underline="false"
            icon="el-icon-document-copy"
            v-clipboard:copy="value"
            v-clipboard:success="clipboardSuccess"
            style="float: right"
            >复制</el-link
          >
          <pre><code class="hljs" v-html="highlightedCode(value, key)"></code></pre>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <import-table ref="import" @ok="handleQuery" />
  </div>
</template>

<script>
import {
  listTable,
  previewTable,
  delTable,
  genCode,
  synchDb
} from '@/api/tool/gen'
import importTable from './importTable'
import hljs from 'highlight.js/lib/highlight'
import 'highlight.js/styles/github-gist.css'
hljs.registerLanguage('java', require('highlight.js/lib/languages/java'))
hljs.registerLanguage('xml', require('highlight.js/lib/languages/xml'))
hljs.registerLanguage('html', require('highlight.js/lib/languages/xml'))
hljs.registerLanguage('vue', require('highlight.js/lib/languages/xml'))
hljs.registerLanguage(
  'javascript',
  require('highlight.js/lib/languages/javascript')
)
hljs.registerLanguage('sql', require('highlight.js/lib/languages/sql'))
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import operatingButton from '@/views/components/operatingButton' //操作按钮
import bottomPage from '@/views/components/bottomPage' //底部分页
export default {
  name: 'Gen',
  components: {
    importTable,
    cardTitleCom,
    seniorSearch,
    operatingButton,
    bottomPage
  },
  data () {
    return {
      // 遮罩层
      loading: true,
      // 唯一标识符
      uniqueId: '',
      // 选中数组
      ids: [],
      // 选中表数组
      tableNames: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表数据
      tableList: [],
      // 日期范围
      dateRange: '',
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        tableName: undefined,
        tableComment: undefined
      },
      // 预览参数
      preview: {
        open: false,
        title: '代码预览',
        data: {},
        activeName: 'domain.java'
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  created () {
    this.getList()
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 128
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },

  activated () {
    const time = this.$route.query.t
    if (time != null && time != this.uniqueId) {
      this.uniqueId = time
      this.queryParams.pageNum = Number(this.$route.query.pageNum)
      this.getList()
    }
  },
  methods: {
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //点击全选所有数据
    async selectAll (value) {
      if (value) {
        this.loadingTable = true
        const res = await listTable({
          ...this.queryParams,
          ...this.dateRange,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableList = res.rows
        //表格多选
        this.tableList.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    /** 查询表集合 */
    getList () {
      this.loading = true
      listTable(this.addDateRange(this.queryParams, this.dateRange)).then(
        response => {
          this.tableList = response.rows
          this.total = response.total
          this.loading = false
        }
      )
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParams.pageNum = 1
      this.getList()
    },
    /** 生成代码操作 */
    handleGenTable (row) {
      const tableNames = row.tableName || this.tableNames
      if (tableNames == '') {
        this.$modal.msgError('请选择要生成的数据')
        return
      }
      if (row.genType === '1') {
        genCode(row.tableName).then(response => {
          this.$modal.msgSuccess('成功生成到自定义路径：' + row.genPath)
        })
      } else {
        this.$download.zip(
          '/api/system/tool/gen/batchGenCode?tables=' + tableNames,
          'code'
        )
      }
    },
    /** 同步数据库操作 */
    handleSynchDb (row) {
      const tableName = row.tableName
      this.$modal
        .confirm('确认要强制同步"' + tableName + '"表结构吗？')
        .then(function () {
          return synchDb(tableName)
        })
        .then(() => {
          this.$modal.msgSuccess('同步成功')
        })
        .catch(() => {})
    },
    /** 打开导入表弹窗 */
    openImportTable () {
      this.$refs.import.show()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.dateRange = []
      this.resetForm('queryForm')
      this.handleQuery()
    },
    /** 预览按钮 */
    handlePreview (row) {
      previewTable(row.tableId).then(response => {
        this.preview.data = response.data
        this.preview.open = true
        this.preview.activeName = 'domain.java'
      })
    },
    /** 高亮显示 */
    highlightedCode (code, key) {
      const vmName = key.substring(key.lastIndexOf('/') + 1, key.indexOf('.vm'))
      var language = vmName.substring(vmName.indexOf('.') + 1, vmName.length)
      const result = hljs.highlight(language, code || '', true)
      return result.value || '&nbsp;'
    },
    /** 复制代码成功 */
    clipboardSuccess () {
      this.$modal.msgSuccess('复制成功')
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.tableId)
      this.tableNames = selection.map(item => item.tableName)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    /** 修改按钮操作 */
    handleEditTable (row) {
      const tableId = row.tableId || this.ids[0]
      const tableName = row.tableName || this.tableNames[0]
      const params = { pageNum: this.queryParams.pageNum }
      this.$tab.openPage(
        '修改[' + tableName + ']生成配置',
        '/tool/gen-edit/index/' + tableId,
        params
      )
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      const tableIds = row.tableId || this.ids
      this.$modal
        .confirm('是否确认删除表编号为"' + tableIds + '"的数据项？')
        .then(function () {
          return delTable(tableIds)
        })
        .then(() => {
          this.getList()
          this.$modal.msgSuccess('删除成功')
        })
        .catch(() => {})
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
    //筛选条件
    .searchCriteria {
      padding-top: 10px;
      .selectFrameUp {
        display: inline-block;
        margin-right: 15px;
      }
    }
  }
  .brandInfo {
    height: calc(100% - 104px);
    //源单信息表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
.el-form-item--small.el-form-item {
  margin-bottom: 0px;
}
</style>
