<template>
  <!-- 调用时长 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"></TablePage>
  </div>
</template>

<script>
import TablePagination from '@/components/tablePage/tablePagination'
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import TablePage from '@/components/tablePage/index.vue'
import { timeList } from '@/api/system/time'
export default {
  name: 'Time',
  components: { TablePage, cardTitleCom, seniorSearch, TablePagination },
  data () {
    return {
      options: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15
        },
        getListApi: timeList,
        title: 'api调用时间统计分页列表',
        rowKey: 'invokeId',
        labelWidth: '100',
        search: [
          {
            label: '接口路径',
            type: 'input',
            tip: 'api接口请求路径',
            model: '',
            filter: 'requestUrl'
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          { type: 'button', tip: '重置', click: 'reset' }
        ],

        columns: [
          { prop: 'requestUrl', label: '请求url', minWidth: '200' },
          { prop: 'requestMethod', label: '请求的方式', minWidth: '50' },
          {
            prop: 'invokeTime',
            label: '接口请求耗时(单位：秒)',
            formatter: propValue => {
              return (propValue / 1000).toFixed(1)
            },
            minWidth: '100'
          },
          { prop: 'createTime', label: '创建时间', minWidth: '155' }
        ]
      }
    }
  }
}
</script>
